import React, { useState, useCallback } from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import Widgets from '../components/widgets/Widgets' 
import Filter from '../components/products/Filter' 
import Product from '../components/products/Product' 
import ComparisonList from '../components/products/ComparisonList' 
import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function Page({ data, location }) {
  const page = data.allWpPage.nodes[0];
  const initialFilters = page.products?.productsSetFilter===true ? page.products?.productsFilters : false;
  const hideFilter = page.products?.productsHideFilter;
  const productData = data?.json?.products;
  const calculatorLink = data?.wp?.acfOptionsOptions?.options?.widgetProducts?.calculatorLink;
  const showWarning = data?.wp?.acfOptionsOptions?.options?.productFeedBadDataWarning;

  const [pageNumber, setPageNumber] = useState(1);
  const itemsPerPage = process.env.NODE_ENV==='development' ? 5 : 10;

  const [filteredProducts, setFilteredProducts] = useState(productData.slice(0, itemsPerPage));
  const [comparisonList, setComparisonList] = useState([]);
  const [numMatches, setNumMatches] = useState(productData.length);
  const [comparisonModalVisible, setComparisonModalVisible] = useState(false);

  /**
   * Add a product to the comparison list
   */
  const addToCompare = function(product) {
	//is the items already on the list?
	if (isOnComparisonList(product)) {
		//remove from the list
		var newList = comparisonList.filter(function( obj ) {
		  return obj.productCode !== product.productCode;
		});
		setComparisonList(newList);
	} else {
	    //we add it to the list as long as there is not already 6 items
		if (comparisonList.length<6) {
			var newList2 = [ ...comparisonList ];
			newList2.push(product);
			setComparisonList(newList2);
		}
	}
  }

  /**
   * Check if the item is on the comparison list
   */
  const isOnComparisonList = function(product) {
	//is the items already on teh list?
	return comparisonList.some(product1 => product1.productCode === product.productCode);
  }

  /**
   * 
   */
  const loadMore = useCallback(() => {
	let newPageNumber = pageNumber+1;
	setPageNumber(newPageNumber);
  }, [pageNumber, setPageNumber]);

  const getPagination = useCallback(() => {
	if ((pageNumber*itemsPerPage)<numMatches) {
		return (
			<button onClick={loadMore} className="button purple solid">Load more results</button>
		)
	}
	return null;
  }, [pageNumber, itemsPerPage, numMatches, loadMore]);

  /**
   * Reset the pagination when the filter updates
   */
  const resetPagination = useCallback(() => {
	setPageNumber(1);
	//clear comparison list
	//setComparisonList([]);
  }, []);

  /**
   * clear the cm[arison list. Called from ComparisonList component]
   */
  const clearComparison = useCallback(() => {
	setComparisonList([]);
  }, []);

  //the number of items we are going to print based on the pagination
  const numItems = pageNumber*itemsPerPage;

  return (
    <Layout className="products">
      <Seo post={page} />
	  <div className="page-widgets">
	    <Hero widget={{layout : 'mini'}} />
	  </div>
	  <article>
		<header className="internal">
			<h1>{page.title}</h1>
			<div className="intro" dangerouslySetInnerHTML={{ __html: page.content }} />
		</header>
		
		{
		  !productData?.length
		  ? <p>No products found</p>
		  : <section className={comparisonModalVisible ? "products comparison" : "products"}>
			  {
				showWarning
				? <div className="warning">
				    <div className="internal">
					  {data?.wp?.acfOptionsOptions?.options?.productFeedBadDataContent?.title && (
						<h3>{data.wp.acfOptionsOptions.options.productFeedBadDataContent.title}</h3>
					  )}
					  {data?.wp?.acfOptionsOptions?.options?.productFeedBadDataContent?.text && (
						<p>{data.wp.acfOptionsOptions.options.productFeedBadDataContent.text}</p>
					  )}
				    </div>
				  </div>
				: null
			  }
			  <Filter initialFilters={initialFilters} products={productData} setFilteredProducts={setFilteredProducts} location={location} resetPagination={resetPagination} numMatches={numMatches} setNumMatches={setNumMatches} hideFilter={hideFilter} />
				
			  <div className="internal">
					
				<ul className="product-list">
				  {/*we still print out the products that are not currently displayed by the pagination so when we print this screen we can include all of them*/}
				  {filteredProducts.map((product, i) => {
					if (i<numItems) {
					  return (<li key={`product${i}`}><Product product={product} addToCompare={addToCompare} selectedForCompare={isOnComparisonList(product)} calculatorLink={calculatorLink ? calculatorLink.uri : false} /></li>);
					} else {
					  return (<li key={`product${i}`} className="hidden"><Product product={product} addToCompare={addToCompare} selectedForCompare={isOnComparisonList(product)} calculatorLink={calculatorLink ? calculatorLink.uri : false} /></li>);
					}
				  })}
				</ul>
					
				<nav>{getPagination()}</nav>
				
			  </div>	
			  <ComparisonList products={comparisonList} clearComparison={clearComparison} setComparisonModalVisible={setComparisonModalVisible} calculatorLink={calculatorLink ? calculatorLink.uri : false} />
			</section>
		}
		
		<Widgets widgets={page.widgets?.pageWidgets} />
		
	  </article>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
	    products {
	      productsHideFilter
	      productsSetFilter
	      productsFilters {
			search
			productRange
			propertyType
			productType
			ltv
			sortBy
			sortReverse
			loanType
			exLocalAuth
			newBuild
			adjCommercial
	      }
	    }
		...WidgetFragment
        ...PageSEOFragment
      }
    }
	wp {
	  acfOptionsOptions {
	    options {
	      widgetProducts {
	        calculatorLink {
	          ... on WpPage {
	            uri
	          }
	        }
	      }
          productFeedBadDataWarning
          productFeedBadDataContent {
            title
            text
          }
	    }
	  }
	}
	json {
	  products {
		productCode
	    productName
		propertyCategories
	    productType
	    maxLtv
	    rate
	    baseRate
        reversionBaseRate
        reversionRate
	    productFeeFixed
	    productFeePerc
		minLoan
		maxLoan
		stressRate
	    productTags
	    productRules {
	      ruleDescr
	    }
		loanType
		exLocalAuth
		newBuild
		adjCommercial
		productRange
		ERCs {
          rate
          term
        }
	    expats
	  }
	}
  }
`